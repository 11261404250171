import { Injectable, inject } from '@angular/core';
import { MonoTypeOperatorFunction, Observable, pipe, Subject, switchMap, throwError, of } from 'rxjs';
import {
	MatSnackBar,
	MatSnackBarConfig,
	MatSnackBarHorizontalPosition,
	MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { catchError, finalize, ignoreElements, share } from 'rxjs/operators';

import { AlertComponent, SnackBarData } from '@mei/common/shared/components/snackbar/snackbar.component';

import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

import { AppError, EXCEED_THRESHOLD_MESSAGE } from '../models/app-error';

const DEFAULT_DURATION_MS = 5000;

export namespace NotificationMessages {

	/** Not implemented. */
	export const NOT_IMPLEMENTED = 'The feature is not implemented yet';

	/** Something went wrong. */
	export const SOMETHING_WENT_WRONG = 'Something went wrong';

	/** Payload is too large. */
	export const PAYLOAD_TOO_LARGE = 'The file size is too large';

	/** Need manager approval. */
	export const NEED_MANAGER_APPROVAL = 'A notification will be sent to the manager to review';
}

type Notification = {

	/** Message. */
	readonly message: string;

	/** Duration of notification message. */
	readonly duration: number;

	/** Whenever snackbar is auto-dismissed after specified duration the callback is called. */
	readonly onDismiss: () => void;

	/** Snackbar horizontal position. */
	readonly horizontalPosition: MatSnackBarHorizontalPosition;

	/** Snackbar vertical position. */
	readonly verticalPosition: MatSnackBarVerticalPosition;
};

/** Notification service. */
@Injectable({ providedIn: 'root' })
export class NotificationService {
	/** Whenever the stream is observed, snackbar messages are displayed. */
	public readonly notification$: Observable<never>;

	private readonly notificationSubject = new Subject<Notification>();

	private readonly snackBar = inject(MatSnackBar);

	private get shouldNotify(): boolean {
		return this.notificationSubject.observed;
	}

	public constructor() {
		this.notification$ = this.notificationSubject.pipe(
			switchMap(({ message, duration, onDismiss, horizontalPosition = 'right', verticalPosition = 'top' }) => {
				const snackBarRef = this.snackBar.open(message, 'Close', {
					duration,
					verticalPosition,
					horizontalPosition,
				});
				return snackBarRef.afterDismissed().pipe(finalize(onDismiss));
			}),
			share(),
			ignoreElements(),
		);
	}

	/**
	 * Notifies an user with a message.
	 * @param message Human-readable message.
	 * @param duration Duration of notification message.
	 */
	public notify(message: string, duration: number = DEFAULT_DURATION_MS, horizontalPos: MatSnackBarHorizontalPosition = 'right',
			verticalPos: MatSnackBarVerticalPosition = 'top'): Promise<void> {
		if (this.shouldNotify) {
			return new Promise(resolve => {
				this.notificationSubject.next({
					message,
					duration,
					onDismiss: resolve,
					horizontalPosition: horizontalPos,
					verticalPosition: verticalPos,
				});
			});
		}
		return Promise.reject(new Error('There is no active subscribers for notifications.'));
	}

	/**
	 * Notify with an custom snackbar.
	 * @param data Human-readable message.
	 * @param config Duration of notification message.
	 */
	public notifyCustom(data: SnackBarData, config?: MatSnackBarConfig<SnackBarData>): void {
		this.snackBar.openFromComponent<AlertComponent>(AlertComponent, {
			data,
			panelClass: 'custom-notification',
			horizontalPosition: 'right',
			verticalPosition: 'top',
			duration: DEFAULT_DURATION_MS,
			...config,
		});
	}

	/**
	 * Operator that catches app error instances and presents a message.
	 * @param continueStreamAfterNotifying Whether stream should be continue after notifying.
	 */
	public notifyOnAppError<T>(continueStreamAfterNotifying = false): MonoTypeOperatorFunction<T | undefined> {
		return pipe(
			catchError((error: unknown) => {
				if (error instanceof AppError) {
					this.notify(error.message);
				}
				return continueStreamAfterNotifying ? of(undefined) : throwError(() => error);
			}),
		);
	}

	/**
	 * Operator that catches app error instances for edit stage with SPECIFIC CONDITIONS and notify with custom snackbar.
	 * @param continueStreamAfterNotifying Whether stream should be continue after notifying.
	 * @param data Snackbar data.
	 */
	public notifyForEditStage<T>(
		data: SnackBarData,
		continueStreamAfterNotifying = false,
	): MonoTypeOperatorFunction<T | undefined> {
		return pipe(
			catchError((error: unknown) => {
				if (error instanceof HttpErrorResponse) {
					if (error.status === HttpStatusCode.UnprocessableEntity && error.error.detail === EXCEED_THRESHOLD_MESSAGE) {
						this.notifyCustom(data);
					} else {
						this.notify(error.error.detail);
					}
				}
				return continueStreamAfterNotifying ? of(undefined) : throwError(() => error);
			}),
		);
	}
}
